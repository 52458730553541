import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';

import { LayoutModule } from './layout/layout.module';

const MODULES: any[] = [
  LayoutModule
];


export const FEATURES_ROUTES: Routes = [
    { path: 'home', loadChildren: '@app/features/home/home.module#HomeModule' },
    { path: 'login', loadChildren: '@app/features/login/login.module#LoginModule' },
    { path: 'contact', loadChildren: '@app/features/contact/contact.module#ContactModule' },
    { path: 'stations', loadChildren: '@app/features/stations/stations.module#StationsModule' },
    { path: 'users', loadChildren: '@app/features/users/users.module#UsersModule' }
];


@NgModule({
  imports: MODULES,
  exports: MODULES
})
export class FeaturesModule {}
