export class Tcode {

  private _czref: string;
  private _ccle: string;
  private _libe01: string;
  private _libe02: string;
  private _libe03: string;
  private _libe04: string;
  private _libe05: string;
  private _libe06: string;
  private _libe07: string;
  private _libe08: string;
  private _code01: number;
  private _code02: number;
  private _code03: number;
  private _code04: number;
  private _code05: number;
  private _code06: number;
  private _code07: number;
  private _code08: number;
  private _xcre: string;
  private _codusr: string;
  private _datcre: Date;
  private _datmod: Date;

  constructor(obj: any) {
    Object.assign(this, obj);
    this._datcre = obj.datcre ? new Date(obj.datcre) : null;
    this._datmod = obj.datmod ? new Date(obj.datmod) : null;
  }

  get czref(): string {
    return this._czref;
  }

  set czref(value: string) {
    this._czref = value;
  }

  get ccle(): string {
    return this._ccle;
  }

  set ccle(value: string) {
    this._ccle = value;
  }

  get libe01(): string {
    return this._libe01;
  }

  set libe01(value: string) {
    this._libe01 = value;
  }

  get libe02(): string {
    return this._libe02;
  }

  set libe02(value: string) {
    this._libe02 = value;
  }

  get libe03(): string {
    return this._libe03;
  }

  set libe03(value: string) {
    this._libe03 = value;
  }

  get libe04(): string {
    return this._libe04;
  }

  set libe04(value: string) {
    this._libe04 = value;
  }

  get libe05(): string {
    return this._libe05;
  }

  set libe05(value: string) {
    this._libe05 = value;
  }

  get libe06(): string {
    return this._libe06;
  }

  set libe06(value: string) {
    this._libe06 = value;
  }

  get libe07(): string {
    return this._libe07;
  }

  set libe07(value: string) {
    this._libe07 = value;
  }

  get libe08(): string {
    return this._libe08;
  }

  set libe08(value: string) {
    this._libe08 = value;
  }

  get code01(): number {
    return this._code01;
  }

  set code01(value: number) {
    this._code01 = value;
  }

  get code02(): number {
    return this._code02;
  }

  set code02(value: number) {
    this._code02 = value;
  }

  get code03(): number {
    return this._code03;
  }

  set code03(value: number) {
    this._code03 = value;
  }

  get code04(): number {
    return this._code04;
  }

  set code04(value: number) {
    this._code04 = value;
  }

  get code05(): number {
    return this._code05;
  }

  set code05(value: number) {
    this._code05 = value;
  }

  get code06(): number {
    return this._code06;
  }

  set code06(value: number) {
    this._code06 = value;
  }

  get code07(): number {
    return this._code07;
  }

  set code07(value: number) {
    this._code07 = value;
  }

  get code08(): number {
    return this._code08;
  }

  set code08(value: number) {
    this._code08 = value;
  }

  get xcre(): string {
    return this._xcre;
  }

  set xcre(value: string) {
    this._xcre = value;
  }

  get codusr(): string {
    return this._codusr;
  }

  set codusr(value: string) {
    this._codusr = value;
  }

  get datcre(): Date {
    return this._datcre;
  }

  set datcre(value: Date) {
    this._datcre = value;
  }

  get datmod(): Date {
    return this._datmod;
  }

  set datmod(value: Date) {
    this._datmod = value;
  }
}
