import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {Session} from '../../app.session';
import {Injectable} from '@angular/core';
import {User} from '@app/models/user.model';

// import {SUPER_ADMIN} from "../../app.constants";

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(private router: Router,
              private session: Session) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (this.session.isLogged) {
      if (this.session.user && new User(this.session.user).isAdmin) {
        return true;
      }
    }

    this.router.navigate(['/home']);
    return false;
  }
}
