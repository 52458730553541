export class Role {

  public logusr: string;
  public codope: string;
  public codusr: string;
  public datcre: Date;
  public datmod: Date;


  constructor(obj:any) {
    Object.assign(this,obj);
    this.datcre = obj.datCre ? new Date(obj.datCre) : null;
    this.datmod = obj.datMod ? new Date(obj.datMod) : null;
  }

}
