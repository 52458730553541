import {Component, Input, OnInit} from '@angular/core';
import {StaannService} from '@app/core/services/staann.service';
import {LayoutService} from '@app/core/services/layout.service';

@Component({
  selector: 'app-paginateur',
  templateUrl: './paginateur.component.html',
  styleUrls: ['./paginateur.component.scss']
})
export class PaginateurComponent implements OnInit {


  @Input() anneeMin: number;
  @Input() anneeMax: number;

  constructor(public staanService: StaannService,
              public layoutService: LayoutService) { }

  ngOnInit() {
  }

  prev($event){
    this.staanService.startDate = this.staanService.startDate - 10;
    this.staanService.stopDate = this.staanService.stopDate -10;
  }

  next($event){
    this.staanService.startDate = this.staanService.startDate + 10;
    this.staanService.stopDate = this.staanService.stopDate +10;
  }

}
