export class GraphData{

  private _statra: string;
  private _datmes: string;
  private _donval: string;

  constructor(obj:any) {
    Object.assign(this,obj);
  }


  get statra(): string {
    return this._statra;
  }

  set statra(value: string) {
    this._statra = value;
  }

  get datmes(): string {
    return this._datmes;
  }

  set datmes(value: string) {
    this._datmes = value;
  }

  get donval(): string {
    return this._donval;
  }

  set donval(value: string) {
    this._donval = value;
  }
}
