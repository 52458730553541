import {Tcode} from '@app/models/tcode.model';

export class StationFile{

  private _nomsta: string;
  private _cartrw: any;
  private _phosta: any;
  private _imaign: any;
  private _vueaer: any;
  private _reltop: any;
  private _docass: any;
  private _DOCass_nl: any;
  private _DOCass_de: any;
  private _DOCass_en: any;
  private _codusr: string;
  private _datcre: Date;
  private _datmod : Date;

  constructor(obj: any) {
    Object.assign(this, obj);
    this._datcre = obj.datcre ? new Date(obj.datcre) : null;
    this._datmod = obj.datmod ? new Date(obj.datmod) : null;
  }

  get nomsta(): string {
    return this._nomsta;
  }

  set nomsta(value: string) {
    this._nomsta = value;
  }

  get cartrw(): any {
    return this._cartrw;
  }

  set cartrw(value: any) {
    this._cartrw = value;
  }

  get phosta(): any {
    return this._phosta;
  }

  set phosta(value: any) {
    this._phosta = value;
  }

  get imaign(): any {
    return this._imaign;
  }

  set imaign(value: any) {
    this._imaign = value;
  }

  get vueaer(): any {
    return this._vueaer;
  }

  set vueaer(value: any) {
    this._vueaer = value;
  }

  get reltop(): any {
    return this._reltop;
  }

  set reltop(value: any) {
    this._reltop = value;
  }

  get docass(): any {
    return this._docass;
  }

  set docass(value: any) {
    this._docass = value;
  }

  get DOCass_nl(): any {
    return this._DOCass_nl;
  }

  set DOCass_nl(value: any) {
    this._DOCass_nl = value;
  }

  get DOCass_de(): any {
    return this._DOCass_de;
  }

  set DOCass_de(value: any) {
    this._DOCass_de = value;
  }

  get DOCass_en(): any {
    return this._DOCass_en;
  }

  set DOCass_en(value: any) {
    this._DOCass_en = value;
  }

  get codusr(): string {
    return this._codusr;
  }

  set codusr(value: string) {
    this._codusr = value;
  }

  get datcre(): Date {
    return this._datcre;
  }

  set datcre(value: Date) {
    this._datcre = value;
  }

  get datmod(): Date {
    return this._datmod;
  }

  set datmod(value: Date) {
    this._datmod = value;
  }
}
