export class CatalogParam{

  private _grpparamlib: string;
  private _paramlib: string;
  private _codpar: string;
  private _codfor: string;
  private _libfor: string;

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  get grpparamlib(): string {
    return this._grpparamlib;
  }

  set grpparamlib(value: string) {
    this._grpparamlib = value;
  }

  get paramlib(): string {
    return this._paramlib;
  }

  set paramlib(value: string) {
    this._paramlib = value;
  }

  get codpar(): string {
    return this._codpar;
  }

  set codpar(value: string) {
    this._codpar = value;
  }

  get codfor(): string {
    return this._codfor;
  }

  set codfor(value: string) {
    this._codfor = value;
  }

  get libfor(): string {
    return this._libfor;
  }

  set libfor(value: string) {
    this._libfor = value;
  }
}
