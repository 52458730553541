export class ViewStaann{

  private _nomsta: string;
  private _codpar: string;
  private _codfor: string;
  private _annmes: string;
  private _nbrper: number;
  private _valmin: string;
  private _valmoy: string;
  private _valmed: string;
  private _valpe5: string;
  private _valmax: string;

  constructor(obj: any) {
    Object.assign(this, obj);
  }


  get nomsta(): string {
    return this._nomsta;
  }

  set nomsta(value: string) {
    this._nomsta = value;
  }

  get codpar(): string {
    return this._codpar;
  }

  set codpar(value: string) {
    this._codpar = value;
  }

  get codfor(): string {
    return this._codfor;
  }

  set codfor(value: string) {
    this._codfor = value;
  }

  get annmes(): string {
    return this._annmes;
  }

  set annmes(value: string) {
    this._annmes = value;
  }

  get nbrper(): number {
    return this._nbrper;
  }

  set nbrper(value: number) {
    this._nbrper = value;
  }

  get valmin(): string {
    return this._valmin;
  }

  set valmin(value: string) {
    this._valmin = value;
  }

  get valmoy(): string {
    return this._valmoy;
  }

  set valmoy(value: string) {
    this._valmoy = value;
  }

  get valmed(): string {
    return this._valmed;
  }

  set valmed(value: string) {
    this._valmed = value;
  }

  get valpe5(): string {
    return this._valpe5;
  }

  set valpe5(value: string) {
    this._valpe5 = value;
  }

  get valmax(): string {
    return this._valmax;
  }

  set valmax(value: string) {
    this._valmax = value;
  }
}
