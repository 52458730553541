import {Role} from '@app/models/role.model';

export class User {

  public logusr?: string;      //Compte de connexion
  public passwd?: string;      //Mot de passe
  public libell?: string;      //Libellé
  public adrema?: string;      //Adresse email
  public numgsm?: string;      //Numéro de GSM
  public remarq?: string;      //Remarque
  public usract?: string;      //Utilisateur actif
  public codusr?: string;      //Utilisateur de création ou modification
  public datcre?: Date;        //Date de création
  public datmod?: Date;        //Date de dernière modification

  public roles?: Role[];

  constructor(obj:any) {
    Object.assign(this,obj);
    this.datcre = obj.datcre ? new Date(obj.datcre) : null;
    this.datmod = obj.datmod ? new Date(obj.datmod) : null;
    this.roles = obj.roles ? obj.roles.map(role => new Role(role)) : null;
  }

  get isAdmin():boolean{
    if(this.roles && this.roles.length>0){
      if(this.roles.find(role => role.codope == "admin")){
        return true;
      }
    }
    return false;
  }

}
