import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {Session} from '../../app.session';
import {Injectable} from '@angular/core';
import {UserService} from '@app/core/services/user.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              private session: Session) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (this.session.user != null) {
      return true;
    }

    this.router.navigate(['/login', state.url]);
    return false;
  }

}
