import {Component, Input, OnInit} from '@angular/core';
import {Station} from '@app/models/station.model';
import {Staann} from '@app/models/staann.model';
import {CatalogParam} from '@app/models/catalog_param.model';
import {LayoutService} from '@app/core/services/layout.service';
import {Router} from '@angular/router';
import {StationService} from '@app/core/services/station.service';

@Component({
  selector: 'app-station-data',
  templateUrl: './station-data.component.html',
  styleUrls: ['./station-data.component.scss']
})
export class StationDataComponent implements OnInit {

  _datas: Staann[];
  @Input() params: CatalogParam[];
  @Input() anneeMin: number;
  @Input() anneeMax: number;

  annees: number[];

  datasTable: any[] = [];


 public get datas() {
   return this._datas;
 }

 @Input()
 public set datas(d: any) {
   this._datas = d;
   this.buildDatasTable();
 }

  legend = [
    {text:"Pas de données", color:"#FFFFFF"},
    {text:"Nombre de prélèvements inférieur ou égal à 7\t", color:"#FFFF00"},
    {text:"Nombre de prélèvements supérieur à 7", color:"#008000"}
  ];

  constructor( private stationService: StationService,
               private route: Router) { }

  ngOnInit() {

  }

  buildDatasTable() {
    this.datasTable.splice(0, this.datasTable.length);
    this.annees = [];
    for (let i = this.anneeMin; i <= this.anneeMax; i++) {
      this.annees.push(i);
    }

    let grpparamlib:string = null;
    let changed:boolean = true;
    this.params.forEach(param => {
      if(grpparamlib == null){
        grpparamlib = param.grpparamlib;
      }else{
        changed = grpparamlib!=param.grpparamlib;
      }
      grpparamlib = param.grpparamlib;

      let nb:number = 0;
      if(changed){
        nb = this.countGrpParamlib(param.grpparamlib)
      }

      let dd = {nb: nb, param: param, grpparamlib: param.grpparamlib, paramlib: param.paramlib, libfor: param.libfor, codpar: param.codpar};

      // Get data for year
      let valueAnnees: string[] = [];
      this.annees.forEach( annee =>{
        let data = this.datas.find(d => (d.codpar == param.codpar && d.annmes == annee.toString()));
        if(data){
          if(data.nbrper <= 7){
            dd[annee]="yellow";
          }else{
            dd[annee]="green";
          }
        }else{
          dd[annee]="";
        }
      });

      this.datasTable.push(dd);
    });

  }

  countGrpParamlib(grpparamlib:string):number{
    return this.params.filter(p => p.grpparamlib == grpparamlib).length;
  }

  show(param){
    this.stationService.selectedParam = param;
    this.route.navigate(["/stations/station/graph"])
  }
}
