export class CatalogDataStationFull{

  private _libpar: string;
  private _codpar: string;
  private _codfor: string;
  private _libfor: string;
  private _annmes: string;
  private _valmoy: number;
  private _nbrper: number;

  constructor(obj: any) {
    Object.assign(this, obj);
  }


  get libpar(): string {
    return this._libpar;
  }

  set libpar(value: string) {
    this._libpar = value;
  }

  get codpar(): string {
    return this._codpar;
  }

  set codpar(value: string) {
    this._codpar = value;
  }

  get codfor(): string {
    return this._codfor;
  }

  set codfor(value: string) {
    this._codfor = value;
  }

  get libfor(): string {
    return this._libfor;
  }

  set libfor(value: string) {
    this._libfor = value;
  }

  get annmes(): string {
    return this._annmes;
  }

  set annmes(value: string) {
    this._annmes = value;
  }

  get valmoy(): number {
    return this._valmoy;
  }

  set valmoy(value: number) {
    this._valmoy = value;
  }

  get nbrper(): number {
    return this._nbrper;
  }

  set nbrper(value: number) {
    this._nbrper = value;
  }
}
