import { Injectable } from '@angular/core';
import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';
import {BaseAbstractService} from '@app/core/services/base-abstract.service';
import {Station} from '@app/models/station.model';
import {Observable, Subject} from 'rxjs';
import {User} from '@app/models/user.model';
import {Tcode} from '@app/models/tcode.model';
import {StationFile} from '@app/models/station_file.model';
import {CatalogParam} from '@app/models/catalog_param.model';
import {Staann} from '@app/models/staann.model';
import {CatalogDataStationFull} from '@app/models/catalog_data_station_full.model';
import {ViewStaann} from '@app/models/view_staann.model';
import {GraphData} from '@app/models/graph_data.models';
import Utils from '@app/shared/services/utils';

@Injectable({
  providedIn: 'root'
})
export class StationService extends BaseAbstractService{

  _selectedStation: Station = null;
  private selectedStationSource = new Subject<Station>();
  selectedStationHandler: Observable<Station> = <Observable<Station>>this.selectedStationSource.asObservable();

  set selectedStation(station: Station) {
    this._selectedStation = station;
    this.selectedStationSource.next(station);
  }
  get selectedStation(): Station {
    return this._selectedStation;
  }

  _selectedParam: CatalogParam = null;
  private selectedParamSource = new Subject<CatalogParam>();
  selectedParamHandler: Observable<CatalogParam> = <Observable<CatalogParam>>this.selectedParamSource.asObservable();

  set selectedParam(param: CatalogParam) {
    this._selectedParam = param;
    this.selectedParamSource.next(param);
  }
  get selectedParam(): CatalogParam {
    return this._selectedParam;
  }

  _stations: Station[] = null;
  private stationsSource = new Subject<Station[]>();
  stationsHandler: Observable<Station[]> = <Observable<Station[]>>this.stationsSource.asObservable();

  set stations(stations: Station[]) {
    this._stations = stations;
    this.stationsSource.next(stations);
  }
  get stations(): Station[] {
    return this._stations;
  }

  get url(): string {
    return this.getApiUrl() + '/stations';
  }

  //Station
  async getStations(bassin: string): Promise<Station[]> {
    let stations = null;
    if(bassin) {
      stations = (await this.post<any>(`${this.url}`, bassin)).map(json => new Station(json));
    }else{
      stations = (await this.get<any>(`${this.url}`)).map(json => new Station(json));
    }
    return stations;
  }

  async getStation(nomsta: string): Promise<Station> {
    let jsonStation = await this.post<any>(`${this.url}/station`, nomsta);
    return new Station(jsonStation);
  }

  async getStationByRiver(rivier: string): Promise<Station[]>{
    let stations = (await this.get<any>(`${this.url}/riviere/`+rivier)).map(json => new Station(json));
    return stations;
  }

  async getSpecifiqueStationFile(nomsta: string, filename: string): Promise<StationFile> {
    let filefilter = {
      name: filename,
      stationName: nomsta
    };
    let stationFile = await this.post<any>(`${this.url}/station/file`, filefilter);
    return new StationFile(stationFile);
  }

  //Catalogue
  async getParamsCatalogueForStation(nomsta: string){
    let catalogParams: CatalogParam[] = (await this.get<any>(`${this.url}/station/params/${nomsta}`)).map(json => new CatalogParam(json));
    return catalogParams;
  }

  async getDateCatalogueForStation(nomsta: string): Promise<string[]> {
    let annees = (await this.get<any>(`${this.url}/station/date/${nomsta}`));
    return annees;
  }

  async getDataCatalogueForStation(nomsta: string, anneeMin, anneeMax): Promise<Staann[]> {
    let datas = (await this.get<any>(`${this.url}/station/data/${nomsta}/${anneeMin}/${anneeMax}`)).map(json => new Staann(json));
    return datas;
  }

  async getDataCatalogueFullForStation(nomsta: string, anneeMin, anneeMax): Promise<CatalogDataStationFull[]> {
    let datas = (await this.get<any>(`${this.url}/station/dataFull/${nomsta}/${anneeMin}/${anneeMax}`)).map(json => new CatalogDataStationFull(json));
    return datas;
  }

  //Graphique
  async getGraphPeriodSelection(nomsta: string, codpar: string, codfor: string): Promise<string[]>{
    let dates:string[] = (await this.get<any>(`${this.url}/station/graph/date/${nomsta}/${codpar}/${codfor}`));
    return dates;
  }

  async getGraphDataSelection(nomsta: string, codpar: string, codfor: string, anneeStart: string, anneeEnd: string): Promise<ViewStaann[]>{
    let datas:ViewStaann[] = (await this.get<any>(`${this.url}/station/graph/data/${nomsta}/${codpar}/${codfor}/${anneeStart}/${anneeEnd}`)).map(json => new ViewStaann(json));
    return datas;
  }

  async getGraphTableSelection(nomsta: string, codpar: string, codfor: string, anneeStart: string, anneeEnd: string): Promise<GraphData[]>{
    let datas:GraphData[] = (await this.get<any>(`${this.url}/station/graph/graph/${nomsta}/${codpar}/${codfor}/${anneeStart}/${anneeEnd}`)).map(json => new GraphData(json));
    return datas;
  }

  //Download
  async getDataDownloadPeriodStation(nomsta: string): Promise<string[]>{
    let dates:string[] = (await this.get<any>(`${this.url}/station/download/date/${nomsta}`));
    return dates;
  }

  async getDataDownloadFileStation(data:any):Promise<any>{
    let promise: Promise<any> = new Promise(async (resolve, reject) => {
      try{
        this.layoutService.loading = true;
        let response:Response =  await this.postBlob(`${this.url}/station/download/data`, data);
        let downloadUrl= URL.createObjectURL(response.body);

        let filename = response.headers.get('filename') ? response.headers.get('filename') :"Data" + "-" + data.nomsta+ "-" + data.selectedDateStart + "-" + data.selectedDateEnd + ".zip";

        Utils.downloadBlob(downloadUrl, filename, response);

      }catch(error){

        this.notificationService.addSingleError('Impossible de télécharger les données actuellement');
        reject(error);
      }
      finally {

        if(this.layoutService.loading){
          this.layoutService.loading = false;
        }
      }
    });
    return promise;
  }

}
