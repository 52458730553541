import {Tcode} from '@app/models/tcode.model';
import {StationFile} from '@app/models/station_file.model';

export class Station {

  private _nomsta: string;             //NOM DE LA STATION
  private _numsta: string;             //NUMERO DE STATION
  private _typsta: string;             //TYPE DE STATION
  private _adrsta: string;             //ADRESSE STATION
  private _possta: string;             //CODE POSTAL STATION
  private _locsta: string;             //LOCALITE STATION
  private _provin: string;             //PROVINCE
  private _basver: string;             //BASSIN VERSANT
  private _rivier: string;             //COURS EAU
  private _staope: string;             //STATION OPERATIONNELLE
  private _loglab: number;             //LONGITUDE
  private _latlab: number;             //LATITUDE
  private _misser: Date;               //DATEMISEENSERVICE
  private _carign: string;             //NUMERODECARTEIGN
  private _coddeb: string;             //CODEDEBIT
  private _commen: string;             //COMMENTAIRES
  private _datcre: Date;               //DATEDECREATION
  private _datmod: Date;               //DATEMODIFICATION
  private _codusr: string;             //CODEUTILISATEUR
  private _pubint: string;             //APUBLIERSURINTERNET
  private _basoff: string;             //BASSINOFFICIEL
  private _basoffs: Tcode[];
  private _codins: string;             //CODEINS
  private _datpub: Date;               //Datemaximumdepublicationdesdonnéesdeuxheures.
  private _nomsta_min: string;         //NomdelastationenminusculesetavecaccentspourrapportCQE

  private _telst1: string;
  private _telst2: string;
  private _codspe: string;
  private _catriv: string;
  private _censta: string;
  private _secsta: string;
  private _finmes: Date;
  private _echell: number;
  private _codori: string;
  private _catlas: number;
  private _supebv: number;
  private _grdbas: string;
  private _supegb: number;
  private _stapri: string;
  private _libsta: string;
  private _empsta: string;
  private _typeau: string;
  private _typeaus: Tcode[];
  private _typcla: string;
  private _typclas: Tcode[];
  private _typmil: string;
  private _milrec: string;
  private _newcom: string;
  private _loggre: number;
  private _latgre: number;
  private _altsta: number;
  private _posriv: number;
  private _posbof: number;
  private _posgrb: number;
  private _dissou: number;
  private _baseur: string;
  private _numcee: string;
  private _maseau: string;
  private _dursta: string;
  private _anndeb: string;
  private _annfin: string;
  private _mascon: string;
  private _derech: Date;
  private _baiint: string;
  private _valiq1: string;
  private _valiq2: string;
  private _valiqs: string;
  private _libsta_nl: string;
  private _libsta_de: string;
  private _libsta_en: string;
  private _newcom_nl: string;
  private _newcom_de: string;
  private _newcom_en: string;
  private _locsta_nl: string;
  private _locsta_de: string;
  private _locsta_en: string;
  private _pranre: string;
  private _masepr: string;
  private _codsit: string;


  private _stationFile: StationFile;

  constructor(obj: any) {
    Object.assign(this, obj);
    this._misser = obj.misser ? new Date(obj.misser) : null;
    this._datcre = obj.datcre ? new Date(obj.datcre) : null;
    this._datmod = obj.datmod ? new Date(obj.datmod) : null;
    this._datpub = obj.datpub ? new Date(obj.datpub) : null;
    this._finmes = obj.finmes ? new Date(obj.finmes) : null;
    this._derech = obj.derech ? new Date(obj.derech) : null;
    this._basoffs = obj.basoffs ? obj.basoffs.map(t => new Tcode(t)) : null;
    this._typeaus = obj.typeaus ? obj.typeaus.map(t => new Tcode(t)) : null;
    this._typclas = obj.typclas ? obj.typclas.map(n => new Tcode(n)) : null;
    this._stationFile = obj.stationFile ? new StationFile(obj.stationFile) : null;
  }


  get nomsta(): string {
    return this._nomsta;
  }

  set nomsta(value: string) {
    this._nomsta = value;
  }

  get numsta(): string {
    return this._numsta;
  }

  set numsta(value: string) {
    this._numsta = value;
  }

  get typsta(): string {
    return this._typsta;
  }

  set typsta(value: string) {
    this._typsta = value;
  }

  get adrsta(): string {
    return this._adrsta;
  }

  set adrsta(value: string) {
    this._adrsta = value;
  }

  get possta(): string {
    return this._possta;
  }

  set possta(value: string) {
    this._possta = value;
  }

  get locsta(): string {
    return this._locsta;
  }

  set locsta(value: string) {
    this._locsta = value;
  }

  get provin(): string {
    return this._provin;
  }

  set provin(value: string) {
    this._provin = value;
  }

  get basver(): string {
    return this._basver;
  }

  set basver(value: string) {
    this._basver = value;
  }

  get rivier(): string {
    return this._rivier;
  }

  set rivier(value: string) {
    this._rivier = value;
  }

  get staope(): string {
    return this._staope;
  }

  set staope(value: string) {
    this._staope = value;
  }

  get loglab(): number {
    return this._loglab;
  }

  set loglab(value: number) {
    this._loglab = value;
  }

  get latlab(): number {
    return this._latlab;
  }

  set latlab(value: number) {
    this._latlab = value;
  }

  get misser(): Date {
    return this._misser;
  }

  set misser(value: Date) {
    this._misser = value;
  }

  get carign(): string {
    return this._carign;
  }

  set carign(value: string) {
    this._carign = value;
  }

  get coddeb(): string {
    return this._coddeb;
  }

  set coddeb(value: string) {
    this._coddeb = value;
  }

  get commen(): string {
    return this._commen;
  }

  set commen(value: string) {
    this._commen = value;
  }

  get datcre(): Date {
    return this._datcre;
  }

  set datcre(value: Date) {
    this._datcre = value;
  }

  get datmod(): Date {
    return this._datmod;
  }

  set datmod(value: Date) {
    this._datmod = value;
  }

  get codusr(): string {
    return this._codusr;
  }

  set codusr(value: string) {
    this._codusr = value;
  }

  get pubint(): string {
    return this._pubint;
  }

  set pubint(value: string) {
    this._pubint = value;
  }

  get basoff(): string {
    return this._basoff;
  }

  set basoff(value: string) {
    this._basoff = value;
  }

  get basoffs(): Tcode[] {
    return this._basoffs;
  }

  set basoffs(value: Tcode[]) {
    this._basoffs = value;
  }

  get codins(): string {
    return this._codins;
  }

  set codins(value: string) {
    this._codins = value;
  }

  get datpub(): Date {
    return this._datpub;
  }

  set datpub(value: Date) {
    this._datpub = value;
  }

  get nomsta_min(): string {
    return this._nomsta_min;
  }

  set nomsta_min(value: string) {
    this._nomsta_min = value;
  }

  get telst1(): string {
    return this._telst1;
  }

  set telst1(value: string) {
    this._telst1 = value;
  }

  get telst2(): string {
    return this._telst2;
  }

  set telst2(value: string) {
    this._telst2 = value;
  }

  get codspe(): string {
    return this._codspe;
  }

  set codspe(value: string) {
    this._codspe = value;
  }

  get catriv(): string {
    return this._catriv;
  }

  set catriv(value: string) {
    this._catriv = value;
  }

  get censta(): string {
    return this._censta;
  }

  set censta(value: string) {
    this._censta = value;
  }

  get secsta(): string {
    return this._secsta;
  }

  set secsta(value: string) {
    this._secsta = value;
  }

  get finmes(): Date {
    return this._finmes;
  }

  set finmes(value: Date) {
    this._finmes = value;
  }

  get echell(): number {
    return this._echell;
  }

  set echell(value: number) {
    this._echell = value;
  }

  get codori(): string {
    return this._codori;
  }

  set codori(value: string) {
    this._codori = value;
  }

  get catlas(): number {
    return this._catlas;
  }

  set catlas(value: number) {
    this._catlas = value;
  }

  get supebv(): number {
    return this._supebv;
  }

  set supebv(value: number) {
    this._supebv = value;
  }

  get grdbas(): string {
    return this._grdbas;
  }

  set grdbas(value: string) {
    this._grdbas = value;
  }

  get supegb(): number {
    return this._supegb;
  }

  set supegb(value: number) {
    this._supegb = value;
  }

  get stapri(): string {
    return this._stapri;
  }

  set stapri(value: string) {
    this._stapri = value;
  }

  get libsta(): string {
    return this._libsta;
  }

  set libsta(value: string) {
    this._libsta = value;
  }

  get empsta(): string {
    return this._empsta;
  }

  set empsta(value: string) {
    this._empsta = value;
  }

  get typeau(): string {
    return this._typeau;
  }

  set typeau(value: string) {
    this._typeau = value;
  }

  get typeaus(): Tcode[] {
    return this._typeaus;
  }

  set typeaus(value: Tcode[]) {
    this._typeaus = value;
  }

  get typcla(): string {
    return this._typcla;
  }

  set typcla(value: string) {
    this._typcla = value;
  }

  get typclas(): Tcode[] {
    return this._typclas;
  }

  set typclas(value: Tcode[]) {
    this._typclas = value;
  }

  get typmil(): string {
    return this._typmil;
  }

  set typmil(value: string) {
    this._typmil = value;
  }

  get milrec(): string {
    return this._milrec;
  }

  set milrec(value: string) {
    this._milrec = value;
  }

  get newcom(): string {
    return this._newcom;
  }

  set newcom(value: string) {
    this._newcom = value;
  }

  get loggre(): number {
    return this._loggre;
  }

  set loggre(value: number) {
    this._loggre = value;
  }

  get latgre(): number {
    return this._latgre;
  }

  set latgre(value: number) {
    this._latgre = value;
  }

  get altsta(): number {
    return this._altsta;
  }

  set altsta(value: number) {
    this._altsta = value;
  }

  get posriv(): number {
    return this._posriv;
  }

  set posriv(value: number) {
    this._posriv = value;
  }

  get posbof(): number {
    return this._posbof;
  }

  set posbof(value: number) {
    this._posbof = value;
  }

  get posgrb(): number {
    return this._posgrb;
  }

  set posgrb(value: number) {
    this._posgrb = value;
  }

  get dissou(): number {
    return this._dissou;
  }

  set dissou(value: number) {
    this._dissou = value;
  }

  get baseur(): string {
    return this._baseur;
  }

  set baseur(value: string) {
    this._baseur = value;
  }

  get numcee(): string {
    return this._numcee;
  }

  set numcee(value: string) {
    this._numcee = value;
  }

  get maseau(): string {
    return this._maseau;
  }

  set maseau(value: string) {
    this._maseau = value;
  }

  get dursta(): string {
    return this._dursta;
  }

  set dursta(value: string) {
    this._dursta = value;
  }

  get anndeb(): string {
    return this._anndeb;
  }

  set anndeb(value: string) {
    this._anndeb = value;
  }

  get annfin(): string {
    return this._annfin;
  }

  set annfin(value: string) {
    this._annfin = value;
  }

  get mascon(): string {
    return this._mascon;
  }

  set mascon(value: string) {
    this._mascon = value;
  }

  get derech(): Date {
    return this._derech;
  }

  set derech(value: Date) {
    this._derech = value;
  }

  get baiint(): string {
    return this._baiint;
  }

  set baiint(value: string) {
    this._baiint = value;
  }

  get valiq1(): string {
    return this._valiq1;
  }

  set valiq1(value: string) {
    this._valiq1 = value;
  }

  get valiq2(): string {
    return this._valiq2;
  }

  set valiq2(value: string) {
    this._valiq2 = value;
  }

  get valiqs(): string {
    return this._valiqs;
  }

  set valiqs(value: string) {
    this._valiqs = value;
  }

  get libsta_nl(): string {
    return this._libsta_nl;
  }

  set libsta_nl(value: string) {
    this._libsta_nl = value;
  }

  get libsta_de(): string {
    return this._libsta_de;
  }

  set libsta_de(value: string) {
    this._libsta_de = value;
  }

  get libsta_en(): string {
    return this._libsta_en;
  }

  set libsta_en(value: string) {
    this._libsta_en = value;
  }

  get newcom_nl(): string {
    return this._newcom_nl;
  }

  set newcom_nl(value: string) {
    this._newcom_nl = value;
  }

  get newcom_de(): string {
    return this._newcom_de;
  }

  set newcom_de(value: string) {
    this._newcom_de = value;
  }

  get newcom_en(): string {
    return this._newcom_en;
  }

  set newcom_en(value: string) {
    this._newcom_en = value;
  }

  get locsta_nl(): string {
    return this._locsta_nl;
  }

  set locsta_nl(value: string) {
    this._locsta_nl = value;
  }

  get locsta_de(): string {
    return this._locsta_de;
  }

  set locsta_de(value: string) {
    this._locsta_de = value;
  }

  get locsta_en(): string {
    return this._locsta_en;
  }

  set locsta_en(value: string) {
    this._locsta_en = value;
  }

  get pranre(): string {
    return this._pranre;
  }

  set pranre(value: string) {
    this._pranre = value;
  }

  get masepr(): string {
    return this._masepr;
  }

  set masepr(value: string) {
    this._masepr = value;
  }

  get codsit(): string {
    return this._codsit;
  }

  set codsit(value: string) {
    this._codsit = value;
  }

  get basoffz():Tcode{
    return this._basoffs.find(t => t.czref=="GROUP5");
  }
  get typeauz():Tcode{
    return this._typeaus.find(t => t.czref=="TYPEAU");
  }
  get typclaz():Tcode{
    return this._typclas.find(t => t.czref=="TYPCLA");
  }


  get stationFile(): StationFile {
    return this._stationFile;
  }

  set stationFile(value: StationFile) {
    this._stationFile = value;
  }
}
