export class Staann{

  private _nomsta: string;
  private _codpar: string;
  private _codfor: string;
  private _annmes: string;
  private _stadif: string;
  private _nbrper: number;
  private _valmin: number;
  private _valmoy: number;
  private _valmed: number;
  private _valmax: number;
  private _datcre: Date;
  private _datmod: Date;
  private _codusr: string;
  private _valpe1: number;
  private _valpe2: number;
  private _valpe3: number;
  private _valpe4: number;
  private _valpe5: number;
  private _valpe6: number;
  private _poudis: number;
  private _ecatyp: number;
  private _tauvar: number;
  private _nbsslq: number;
  private _nbldma: number;
  private _stamin: string;
  private _stamax: string;
  private _stamoy: string;
  private _stamed: string;
  private _stape1: string;
  private _stape2: string;
  private _stape3: string;
  private _stape4: string;
  private _stape5: string;
  private _stape6: string;
  private _moyeta: number;
  private _stamoe: string;
  private _p90pon: number;
  private _stap9p: string;
  private _p90eta: number;
  private _stap9e: string;
  private _p10pon: number;
  private _stap1p: string;
  private _p10eta: number;
  private _stap1e: string;

  constructor(obj: any) {
    Object.assign(this, obj);
    this._datcre = obj.datcre ? new Date(obj.datcre) : null;
    this._datmod = obj.datmod ? new Date(obj.datmod) : null;
  }


  get nomsta(): string {
    return this._nomsta;
  }

  set nomsta(value: string) {
    this._nomsta = value;
  }

  get codpar(): string {
    return this._codpar;
  }

  set codpar(value: string) {
    this._codpar = value;
  }

  get codfor(): string {
    return this._codfor;
  }

  set codfor(value: string) {
    this._codfor = value;
  }

  get annmes(): string {
    return this._annmes;
  }

  set annmes(value: string) {
    this._annmes = value;
  }

  get stadif(): string {
    return this._stadif;
  }

  set stadif(value: string) {
    this._stadif = value;
  }

  get nbrper(): number {
    return this._nbrper;
  }

  set nbrper(value: number) {
    this._nbrper = value;
  }

  get valmin(): number {
    return this._valmin;
  }

  set valmin(value: number) {
    this._valmin = value;
  }

  get valmoy(): number {
    return this._valmoy;
  }

  set valmoy(value: number) {
    this._valmoy = value;
  }

  get valmed(): number {
    return this._valmed;
  }

  set valmed(value: number) {
    this._valmed = value;
  }

  get valmax(): number {
    return this._valmax;
  }

  set valmax(value: number) {
    this._valmax = value;
  }

  get datcre(): Date {
    return this._datcre;
  }

  set datcre(value: Date) {
    this._datcre = value;
  }

  get datmod(): Date {
    return this._datmod;
  }

  set datmod(value: Date) {
    this._datmod = value;
  }

  get codusr(): string {
    return this._codusr;
  }

  set codusr(value: string) {
    this._codusr = value;
  }

  get valpe1(): number {
    return this._valpe1;
  }

  set valpe1(value: number) {
    this._valpe1 = value;
  }

  get valpe2(): number {
    return this._valpe2;
  }

  set valpe2(value: number) {
    this._valpe2 = value;
  }

  get valpe3(): number {
    return this._valpe3;
  }

  set valpe3(value: number) {
    this._valpe3 = value;
  }

  get valpe4(): number {
    return this._valpe4;
  }

  set valpe4(value: number) {
    this._valpe4 = value;
  }

  get valpe5(): number {
    return this._valpe5;
  }

  set valpe5(value: number) {
    this._valpe5 = value;
  }

  get valpe6(): number {
    return this._valpe6;
  }

  set valpe6(value: number) {
    this._valpe6 = value;
  }

  get poudis(): number {
    return this._poudis;
  }

  set poudis(value: number) {
    this._poudis = value;
  }

  get ecatyp(): number {
    return this._ecatyp;
  }

  set ecatyp(value: number) {
    this._ecatyp = value;
  }

  get tauvar(): number {
    return this._tauvar;
  }

  set tauvar(value: number) {
    this._tauvar = value;
  }

  get nbsslq(): number {
    return this._nbsslq;
  }

  set nbsslq(value: number) {
    this._nbsslq = value;
  }

  get nbldma(): number {
    return this._nbldma;
  }

  set nbldma(value: number) {
    this._nbldma = value;
  }

  get stamin(): string {
    return this._stamin;
  }

  set stamin(value: string) {
    this._stamin = value;
  }

  get stamax(): string {
    return this._stamax;
  }

  set stamax(value: string) {
    this._stamax = value;
  }

  get stamoy(): string {
    return this._stamoy;
  }

  set stamoy(value: string) {
    this._stamoy = value;
  }

  get stamed(): string {
    return this._stamed;
  }

  set stamed(value: string) {
    this._stamed = value;
  }

  get stape1(): string {
    return this._stape1;
  }

  set stape1(value: string) {
    this._stape1 = value;
  }

  get stape2(): string {
    return this._stape2;
  }

  set stape2(value: string) {
    this._stape2 = value;
  }

  get stape3(): string {
    return this._stape3;
  }

  set stape3(value: string) {
    this._stape3 = value;
  }

  get stape4(): string {
    return this._stape4;
  }

  set stape4(value: string) {
    this._stape4 = value;
  }

  get stape5(): string {
    return this._stape5;
  }

  set stape5(value: string) {
    this._stape5 = value;
  }

  get stape6(): string {
    return this._stape6;
  }

  set stape6(value: string) {
    this._stape6 = value;
  }

  get moyeta(): number {
    return this._moyeta;
  }

  set moyeta(value: number) {
    this._moyeta = value;
  }

  get stamoe(): string {
    return this._stamoe;
  }

  set stamoe(value: string) {
    this._stamoe = value;
  }

  get p90pon(): number {
    return this._p90pon;
  }

  set p90pon(value: number) {
    this._p90pon = value;
  }

  get stap9p(): string {
    return this._stap9p;
  }

  set stap9p(value: string) {
    this._stap9p = value;
  }

  get p90eta(): number {
    return this._p90eta;
  }

  set p90eta(value: number) {
    this._p90eta = value;
  }

  get stap9e(): string {
    return this._stap9e;
  }

  set stap9e(value: string) {
    this._stap9e = value;
  }

  get p10pon(): number {
    return this._p10pon;
  }

  set p10pon(value: number) {
    this._p10pon = value;
  }

  get stap1p(): string {
    return this._stap1p;
  }

  set stap1p(value: string) {
    this._stap1p = value;
  }

  get p10eta(): number {
    return this._p10eta;
  }

  set p10eta(value: number) {
    this._p10eta = value;
  }

  get stap1e(): string {
    return this._stap1e;
  }

  set stap1e(value: string) {
    this._stap1e = value;
  }
}
