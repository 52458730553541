import {Component, Input, OnInit} from '@angular/core';
import {Staann} from '@app/models/staann.model';
import {CatalogDataStationFull} from '@app/models/catalog_data_station_full.model';
import {StationService} from '@app/core/services/station.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-station-data-admin',
  templateUrl: './station-data-admin.component.html',
  styleUrls: ['./station-data-admin.component.scss']
})
export class StationDataAdminComponent implements OnInit {

  _datas: CatalogDataStationFull[];
  @Input() anneeMin: number;
  @Input() anneeMax: number;

  public get datas() {
    return this._datas;
  }

  @Input()
  public set datas(d: any) {
    this._datas = d;
    this.buildDatasTable();
  }

  annees: number[];

  datasTable: any[] = [];

  params: Set<any> = new Set();

  legend = [
    {text:"Pas de données", color:"#FFFFFF"},
    {text:"Nombre de prélèvements inférieur ou égal à 7\t", color:"#FFFF00"},
    {text:"Nombre de prélèvements supérieur à 7", color:"#008000"}
    ];

  constructor(private stationService: StationService,
              private route: Router) { }

  ngOnInit() {

  }

  buildDatasTable(){
    this.params = new Set();
    this.datasTable.splice(0, this.datasTable.length);
    this.annees = [];
    for (let i = this.anneeMin; i <= this.anneeMax; i++) {
      this.annees.push(i);
    }
    this.datas.forEach(d => {

      if(!this.params.has(d.libpar)){
        let dd = {libpar: d.libpar, codpar: d.codpar, libfor: d.libfor};

        // Get data for year
        let valueAnnees: string[] = [];
        this.annees.forEach( annee =>{
          let data = this.datas.find(df => (df.codpar == d.codpar && df.annmes == annee.toString()));
          if(data){
            if(data.nbrper <= 7){
              dd[annee]="yellow";
            }else{
              dd[annee]="green";
            }
          }else{
            dd[annee]="";
          }
        });

        this.datasTable.push(dd);
        this.params.add(d.libpar);
      }
    });
  }

  show(param){
    this.stationService.selectedParam = param;
    this.route.navigate(["/stations/station/graph"])
  }

}
