import { Injectable } from '@angular/core';
import {BaseAbstractService} from '@app/core/services/base-abstract.service';
import {environment} from '@env/environment';
import {Tcode} from '@app/models/tcode.model';
import {Staann} from '@app/models/staann.model';
import {Observable, Subject} from 'rxjs';
import {Station} from '@app/models/station.model';

@Injectable({
  providedIn: 'root'
})
export class StaannService extends BaseAbstractService{

  _startDate: number = null;
  stopDate: number = null;

  private changeDateSource = new Subject<number>();
  changeDateHandler: Observable<number> = <Observable<number>>this.changeDateSource.asObservable();

  get startDate(){
    return this._startDate;
  }

  set startDate(startDate: number){
    this._startDate = startDate;
    if(startDate != null){
      this.changeDateSource.next(startDate);
    }
  }


  get url(): string {
    return this.getApiUrl() + '/staanns';
  }

  ///staanns/station/date/{nomsta}
}
